import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4'; // Import ReactGA (default export)
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import PDFToImage from './pages/PDFToImage';
import ImageToPDF from './pages/ImageToPDF';
import PDFToDoc from './pages/PDFToDoc';
import DocToPDF from './pages/DocToPDF';
import PDFToExcel from './pages/PDFToExcel';
import PdfToPptx from './pages/PdfToPptx';
import ExcelToPdf from './pages/ExcelToPdf';
import CsvToJson from './pages/CsvToJson';
import MarkdownToHtml from './pages/MarkdownToHtml';
import HtmlToPdf from './pages/HtmlToPdf';

// Initialize Google Analytics with your Measurement ID
ReactGA.initialize('G-68RWJXSXM0');

// Custom component to track page views
const TrackPageViews = () => {
  const location = useLocation();

  useEffect(() => {
    // Track page view on route change
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null; // This component doesn't render anything
};

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        {/* Track page views */}
        <TrackPageViews />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pdf-to-image" element={<PDFToImage />} />
          <Route path="/image-to-pdf" element={<ImageToPDF />} />
          <Route path="/pdf-to-doc" element={<PDFToDoc />} />
          <Route path="/doc-to-pdf" element={<DocToPDF />} />
          <Route path="/pdf-to-excel" element={<PDFToExcel />} />
          <Route path="/pdf-to-pptx" element={<PdfToPptx />} />
          <Route path="/excel-to-pdf" element={<ExcelToPdf />} />
          <Route path="/csv-to-json" element={<CsvToJson />} />
          <Route path="/markdown-to-html" element={<MarkdownToHtml />} />
          <Route path="/html-to-pdf" element={<HtmlToPdf />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;